import { formatDate } from "@/helpers/dateProcessor";

const StatusLabels: any = {
  approved: "Approved",
  pendingApproval: "Pending Approval",
  rejected: "Rejected",
  spam: "Spam"
};

export const tableColumnsListAgents = {
  createdOn: {
    formatDataKey: "Date Submitted",
    formatDataValue: (arg: Date) => (arg ? formatDate(arg) : arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0,
    colWidth: 170
  },
  agencyName: {
    formatDataKey: "Agency Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1
  },

  status: {
    formatDataKey: "Status",
    formatDataValue: (arg: string) => StatusLabels[arg] || "N/A",
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2,
    colWidth: 150
  }
};

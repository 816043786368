
import Vue from "vue";
import { capitalizeFirstLetter } from "@/helpers/generalHelpers";

export default Vue.extend({
  name: "agentApplicationDetailsComponent",
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  methods: {
    capitalizeFirstLetter(word: string) {
      return capitalizeFirstLetter(word);
    }
  }
});

import Vue from "vue";
import { agentApplicationsMapActions } from "@/store/modules/agentApplications";
import { makeBlobUrlFromBase64 } from "@/helpers/downloadLossForm";
import { getOrigin } from "@/helpers";
export default Vue.extend({
  data() {
    return {
      loading: false,
      loadingText: "",
      errorMessage: ""
    };
  },
  methods: {
    ...agentApplicationsMapActions(["printAgentApplication"]),

    async getAgentApplicationURL(agentApplicationId: string) {
      try {
        this.loading = true;
        const data = {
          _id: agentApplicationId
        };
        const fileURL = await this.printAgentApplication(data);
        return makeBlobUrlFromBase64(fileURL.document);
      } finally {
        this.loading = false;
      }
    },
    async printCallback(agentApplicationId: string) {
      const newTab = window.open(
        `${getOrigin()}/loading-preview/noMessage`,
        "_blank"
      );
      try {
        this.loadingText = "Printing agent application. Please wait...";
        const fileURL = await this.getAgentApplicationURL(agentApplicationId);
        if (fileURL) {
          if (newTab) {
            newTab.location.href = fileURL;
          }
          this.$appNotifySuccess("Agent Application printed.");
        }
      } catch (error) {
        newTab?.close();
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
        this.$appNotifyError(this.errorMessage);
      } finally {
        this.loadingText = "";
      }
    }
  }
});


import * as selectOptions from "@/helpers/selectOptions";
import * as types from "@/store/mutation-types";

import {
  agentApplicationsMapActions,
  agentApplicationsMapGetters,
  agentApplicationsMapMutations,
  agentApplicationsMapState
} from "@/store/modules/agentApplications";
import { getDefaultApproveAgent } from "@/helpers/defaultAgentApplication";
import { focusInputFields } from "@/helpers/focusInputField";
import {
  companiesMapActions,
  companiesMapGetters
} from "@/store/modules/companies";
import AgentApplicationDetails from "@/components/AgentApplication/AgentApplicationDetails.vue";
import VueWithMixins from "@/helpers/mixins";
import AgentApplicationMixin from "./AgentApplicationMixin";
import { IAgentApplications } from "@/store/modules/agentApplications/types";
export default VueWithMixins(AgentApplicationMixin).extend({
  name: "agentApplicationDetails",

  data() {
    return {
      resourceNotFound: false,
      stateOptions: selectOptions.states,
      streetTypeOptions: selectOptions.streetTypes,
      streetDirectionOptions: selectOptions.streetDirections,
      entityTypeOptions: selectOptions.entityTypeOptions,
      checkboxOptions: selectOptions.marketingPlansCheckBox,
      disabled: true,
      companyIds: [],
      agentCode: "",
      successMessage: ""
    };
  },
  components: {
    AgentApplicationDetails
  },
  computed: {
    ...agentApplicationsMapGetters([
      "getAgentApplicationById",
      "editingAgentApplication"
    ]),
    showApprovedButton(): boolean {
      return !!(
        this.editing &&
        (this.editing as any).status !== "approved" &&
        (this.editing as any).status !== "rejected"
      );
    },
    showDeclineButton(): boolean {
      return !!(
        this.editing &&
        (this.editing as any).status !== "rejected" &&
        (this.editing as any).status !== "approved"
      );
    },
    topButtonSecondaryItems(): {
      text: string;
      key: string;
      subItems: { title: string; command: string }[];
    } {
      const subItems: { title: string; command: string }[] = [
        {
          title: "Download",
          command: "download-agent-application"
        },
        {
          title: "Print",
          command: "print-application"
        }
      ];
      const primary = {
        text: "Actions",
        key: "actions"
      };
      if (this.showDeclineButton) {
        subItems.push({
          title: "Reject",
          command: "reject"
        });
      } else {
        subItems.push({
          title: "Cancel",
          command: "cancel"
        });
      }
      return {
        ...primary,
        subItems
      };
    },
    ...companiesMapGetters(["companies"]),
    agentApplicationID(): any {
      return this.$route.params.agentApplicationID;
    },
    checkOther(): any {
      return this.editing?.marketingPlans.includes("Other");
    },
    ...agentApplicationsMapState([
      "editing",
      "makingApiRequest",
      "editingApprove"
    ])
  },
  created() {
    this.getAgentApplication(this.agentApplicationID)
      .then((data: IAgentApplications) => {
        this.SET_EDIT(data);
        this.SET_EDIT_APPROVE(getDefaultApproveAgent({}));
        this.getCompanies({ query: {}, drop_store: true });
      })
      .catch((e: Error) => {
        this.resourceNotFound = true;
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      });
  },
  destroyed() {
    this.SET_EDIT(" " as any);
    this.SET_EDIT_APPROVE(getDefaultApproveAgent({}));
  },
  methods: {
    ...companiesMapActions(["getCompanies"]),
    async createCallback(): Promise<any> {
      this.$router.push(`/admin/new_registered_agents`).catch(() => {});
    },
    openModal() {
      this.$modal.show("approveAgentApplicationModal");
    },
    async downloadFile() {
      try {
        const url = await this.getAgentApplicationURL(this.agentApplicationID);
        if (url) {
          const element = document.createElement("a");
          element.href = url;
          element.download = "Agent Application";
          element.target = "_blank";
          element.click();
        }
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    checkInput(value: any) {
      //do nothing
    },
    async approve(): Promise<any> {
      try {
        // close button after approving agent application
        if (this.companyIds.length < 1) {
          this.errorMessage = "Company ID is required";
          focusInputFields(this.$refs.field_companyIds);
          return;
        }
        if (!this.agentCode) {
          this.errorMessage = "Agent Code is Required";
          focusInputFields(this.$refs.field_agentCode);
          return;
        }
        // if everything ok approve
        const payload = {
          companyIds: this.companyIds,
          agentCode: this.agentCode
        };
        let id = this.agentApplicationID;
        let data = { id: id, update: payload };
        await this.approveAgentApplication(data);
        this.$modal.hide("approveAgentApplicationModal");
        this.$appNotifySuccess("Application Approved");
        this.$router.replace("/admin/agent-applications").catch(() => {});
      } catch (err) {
        this.errorMessage = err.message;
        this.$bugSnagClient.notify(err);
      }
    },
    async reject(): Promise<any> {
      try {
        const payload = {
          status: "rejected"
        };
        let id = this.agentApplicationID;
        let data = { id: id, update: payload };
        await this.updateAgentApplications(data);

        this.$appNotifySuccess("Application Rejected");
        this.$router.replace("/admin/agent-applications").catch(() => {});
      } catch (err) {
        this.errorMessage = err.message;
        this.$bugSnagClient.notify(err);
      }
    },
    async onToolbarItemClick(event: string) {
      switch (event) {
        case "approve":
          this.openModal();
          break;
        case "reject":
          this.reject();
          break;
        case "cancel":
          this.$router.push("/admin/agent-applications").catch(() => {});
          break;
        case "download-agent-application":
          await this.downloadFile();
          break;
        case "print-application":
          await this.printCallback(this.agentApplicationID);
          break;
      }
    },
    ...agentApplicationsMapActions([
      "getAgentApplication",
      "approveAgentApplication",
      "updateAgentApplications"
    ]),
    ...agentApplicationsMapMutations({
      [types.SET_EDIT]: types.SET_EDIT,
      editField: types.SET_EDIT_FIELD
    }),
    ...agentApplicationsMapMutations(["SET_EDIT_APPROVE"]),
    getValue(data: object, property: string): string {
      return data ? `${data}.${property}` : "";
    }
  }
});
